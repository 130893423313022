export const COLORS = [
    {
        name: "черный", // 0
        color: "#000000",
    },
    {
        name: "белый", // 1
        color: "#ffffff",
    },
    {
        name: "красный", // 2
        color: "#ff0000",
    },
    {
        name: "серебристый пион", // 3
        color: "#EFDDC5",
    },
    {
        name: "фуксия", // 4
        color: "#f754e1",
    },
    {
        name: "пыльная Роза", // 5
        color: "#B5727F",
    },
    {
        name: "синий", // 6
        color: "#0000ff",
    },
    {
        name: "желтый неон", // 7
        color: "#faed27",
    },
    {
        name: "розовый неон", // 8
        color: "#fc0fc0",
    },
    {
        name: "экрю", // 9
        color: "#eeceac",
    },
    {
        name: "лавандовый", // 10
        color: "#e6e6fa",
    },
    {
        name: "золотой", // 11
        color: "#ffd700",
    },
    {
        name: "серебряный", // 12
        color: "#c0c0c0",
    },
    {
        name: "голубой", // 13
        color: "#42aaff",
    },
    {
        name: "серый", // 14
        color: "#808080",
    },

];
export const MATERIALS = [
    "вышивка", //0
    "кружево", // 1
    "сетка", // 2
    "хлопок", //3
    "атлас", //4
    "бархат", //5
    "искусственный шелк", //6
    "перья", //7
    "блестящий материал", //8
    "вискоза", //9
    "микрофибра" // 10
];
export const CATEGORY_LIST = [
    {
        name: "Боди",
        sizes: ["xs", "s", "m", "l"],
        link: "/body"
    },
    {
        name: "Одежда для дома",
        sizes: ["s", "m", "l"],
        link: "/home"
    },
    {
        name: "Бюстье",
        sizes: ["65A", "65B", "65C", "65D", "65E",
            "70A", "70B", "70C", "70D",
            "75A", "75B", "75C", "75D",
            "80A", "80B", "80C",
            "85A", "85B"
        ],
        link: "/bustie"
    },
    {
        name: "Трусики",
        sizes: ["xxs", "xs", "s", "m", "l", "xl"],
        link: "/trusiki"

    },
    {
        name: "Бюстгальтеры",
        sizes: ["65A", "65B", "65C", "65D", "65E",
            "70A", "70B", "70C", "70D",
            "75A", "75B", "75C", "75D",
            "80A", "80B", "80C",
            "85A", "85B"
        ],
        link: "/byustgaltery"
    },
    {
        name: "Сертификаты",
        sizes: ["1000"],
        link: "/sertificati"
    },
    {
        name: "Пояса для чулок",
        sizes: ["xs", "s", "m", "l"],
        link: "/poyasa"
    },
    {
        name: "Аксессуары",
        sizes: ["one size"],
        link: "/accessories"
    },
    {
        name: "Купальники",
        sizes: ["one size"],
        link: "/kupalniki"
    },
];