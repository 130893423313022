import axios from 'axios';
import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import './App.css';
import AddModal from "./components/AddModal";
import {APP_LINK, DELETE_ITEM_LINK, DELETE_REC_ITEM_LINK, RECOMMENDATIONS_APP_LINK} from "./utils/links";
import {COLORS, MATERIALS} from "./utils/categories";
import AddRecomendationsModal from "./components/AddRecomendationsModal";


const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;
const FlexColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const FlexColumnStart = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
`;
const FlexRowBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;

const TopValueWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: row;
  margin-top: 22px;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const StyledInput = styled.input`
  font-size: 16px;
  padding: 18px;
  width: 300px;
  background: #f5f1f1;
  color: #000;
  border: none;

  ::placeholder {
    color: #000;
    opacity: 0.5;
  }
`;

const StyledButton = styled.button`
  width: 338px;
  padding: 15px;
  font-size: 20px;
  font-weight: 300;
  margin-top: 10px;
  cursor: pointer;
  height: 60px;
  border-radius: 10px;
  border: none;
  background: #d9d9d9;

  &:hover {
    opacity: 0.9;
  }
`;
const Page = styled(FlexColumn)`
  width: calc(100% - 30px);
`;
const TableTitle = styled.div`
  font-size: 15px;
  width: 200px;
  text-align: left;
  padding: 10px;
  border-right: 1px solid #d3cbcb;
  margin-top: 0;
  margin-bottom: 0;
  height: 30px;
`;

const TableContent = styled(TableTitle)`
  height: 90px;
`;

const TableRow = styled(FlexRow)`
  border-bottom: 1px solid #d3cbcb;
  width: 100%;
  justify-content: start;
`;
const TableRowTitle = styled(TableRow)`
  background: linear-gradient(135deg, #499ACC, #E60754);
  color: #fff;
`;

const ActionButton = styled.button`
  padding: 15px;
  background: linear-gradient(135deg, #499ACC, #E60754);
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: calc(100% - 24px);
  margin-bottom: 10px;
`;

const Title = styled.p`
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
`;


function App() {

    const [apiKey, setApiKey] = useState("");
    const [entered, setEntered] = useState(false);
    const handleChangeKey = useCallback((event: any) => setApiKey(event.target.value), []);
    const [products, setProducts] = useState([]);
    const [recList, setRecList] = useState([]);

    // @ts-ignore
    const loadData = () => {
        axios({
            method: 'get',
            url: `${APP_LINK}d4es9b9bcdttshj4dis4`
        }).then(function (response) {
            setProducts(response.data);
        });
        axios({
            method: 'get',
            url: RECOMMENDATIONS_APP_LINK
        }).then(function (response) {
            setRecList(response.data);
        });
    };

    const deleteData = (id: number) => {
        const formData = new FormData();
        formData.append("Id", id.toString());
        formData.append("AccessKey", apiKey);

        axios.delete(DELETE_ITEM_LINK, {
            data: {
                Id: id,
                AccessKey: apiKey
            }
        })
            .then(function (response) {
                console.log(response.data);
                loadData();
            });
    };
    const deleteRecById = (id: number) => {
        const formData = new FormData();
        formData.append("Id", id.toString());
        formData.append("AccessKey", apiKey);

        axios.delete(DELETE_REC_ITEM_LINK, {
            data: {
                Id: id,
                AccessKey: apiKey
            }
        })
            .then(function (response) {
                console.log(response.data);
                loadData();
            });
    };


    const enterApp = () => {
        if (apiKey.length > 10) {
            setEntered(true);
            loadData();
        }
    };

    const [showAddModal, setShowAddModal] = useState(false);
    const [showRecModal, setShowRecModal] = useState(false);

    const findProductByIndex = (index: number) => {
        // @ts-ignore
        return products && products.find(product => product.Id === index);
    };

    return (
        <div className="App">
            {showAddModal && <AddModal showModal={setShowAddModal} apiKey={apiKey} loadData={loadData}/>}
            {showRecModal && <AddRecomendationsModal showModal={setShowRecModal} apiKey={apiKey} loadData={loadData}/>}
            <header className="App-header">
                <Page>
                    {!entered && <FlexColumn style={{height: "100vh"}}>
                        <StyledInput type="text" value={apiKey} placeholder="API KEY" onChange={handleChangeKey}/>
                        <StyledButton onClick={enterApp}>ВОЙТИ</StyledButton>
                    </FlexColumn>}
                    {entered && <>
                        <TopValueWrapper>
                            <StyledButton onClick={() => setShowAddModal(true)}
                                          style={{width: 200, fontSize: 16, height: 50}}>
                                Добавить товар</StyledButton>
                            <StyledButton onClick={() => setShowRecModal(true)}
                                          style={{marginLeft: 15, width: 250, fontSize: 16, height: 50}}>
                                Добавить рекомендации</StyledButton>
                        </TopValueWrapper>

                        <Title>Список товаров</Title>
                        <TableRowTitle>
                            <TableTitle style={{width: 100}}>ID</TableTitle>
                            <TableTitle>Название</TableTitle>
                            <TableTitle style={{width: 140}}>Цена</TableTitle>
                            <TableTitle>Цвета</TableTitle>
                            <TableTitle>Материалы</TableTitle>
                            <TableTitle>Картинки</TableTitle>
                            <TableTitle>Действия</TableTitle>
                        </TableRowTitle>
                        {products && products.map((product: any) => (
                            <TableRow>
                                <TableContent style={{width: 100}}>#{product.Id}</TableContent>
                                <TableContent>{product.Name}</TableContent>
                                <TableContent style={{width: 140}}>{product.Price}</TableContent>
                                <TableContent>{product.ColorIds.map((color: any, id: number) =>
                                    `${COLORS[color].name}${id + 1 === product.ColorIds.length ? "" : ","} `)}</TableContent>
                                <TableContent>{product.MaterialIds.map((color: any, id: number) =>
                                    `${MATERIALS[color]}${id + 1 === product.MaterialIds.length ? "" : ","} `)}</TableContent>
                                <TableContent>
                                    {product.ImageLinks.map((imageLink: string) => <a href={imageLink} target="_blank"
                                                                                      rel="noreferrer">
                                        <img src={imageLink} alt="" width={40}/>
                                    </a>)}
                                </TableContent>
                                <TableContent>
                                    <ActionButton onClick={() => deleteData(product.Id)}>
                                        Удалить
                                    </ActionButton>
                                </TableContent>
                            </TableRow>
                        ))}

                        <Title>Список рекомендаций</Title>
                        <TableRowTitle>
                            <TableTitle>ID</TableTitle>
                            <TableTitle>IDшники</TableTitle>
                            <TableTitle>Названия</TableTitle>
                            <TableTitle>Картинки (первые)</TableTitle>
                            <TableTitle>Действия</TableTitle>
                        </TableRowTitle>
                        {recList.map((recItem) => (
                            <TableRow>
                                <TableContent>
                                    #{recItem && recItem.Id}
                                </TableContent>
                                <TableContent>
                                    {recItem && recItem.ProductIds.map((content: any, id: number) =>
                                        `${content}${id + 1 === recItem.length ? "" : ", "}`)}
                                </TableContent>
                                <TableContent>
                                    {recItem && recItem.ProductIds.map((recID: number, id: number) =>
                                        // @ts-ignore
                                        `${findProductByIndex(recID)?.Name || "..."}${id + 1 === recItem.length ? "" : ", "}`
                                    )}
                                </TableContent>
                                <TableContent>
                                    {recItem && recItem.ProductIds.map((recID: number) =>
                                        // @ts-ignore
                                        findProductByIndex(recID)
                                            // @ts-ignore
                                            ? <a href={findProductByIndex(recID)?.ImageLinks[0]} target="_blank" style={{marginRight: 5}}
                                                // @ts-ignore
                                                 rel="noreferrer"><img src={findProductByIndex(recID)?.ImageLinks[0]} alt="" width={40}/>
                                            </a>
                                            : <></>
                                    )}
                                </TableContent>
                                <TableContent>
                                    <ActionButton onClick={() => deleteRecById(recItem.Id)}>
                                        Удалить
                                    </ActionButton>
                                </TableContent>
                            </TableRow>
                        ))}
                    </>
                    }
                </Page>
            </header>
        </div>
    );
}

export default App;

