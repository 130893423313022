import React, {useCallback, useState} from "react";
import styled from "styled-components";
import {truncateString} from "../utils/truncate";
import {POST_ITEM_LINK} from "../utils/links";
import axios from "axios";
import {CATEGORY_LIST, COLORS, MATERIALS} from "../utils/categories";

const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
`;

const ModalWindow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  width: 500px;
  padding: 14px;
`;

const StyledInput = styled.input`
  font-size: 16px;
  padding: 8px;
  width: 220px;
  background: #fff;
  color: #000;
  border: 1px solid #888888;
  border-radius: 5px;
  outline: none;

  ::placeholder {
    color: #000;
    opacity: 0.5;
  }
`;
const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;
  width: 100%;
  margin-left: 22px;
`;
const FlexColumn = styled(FlexRow)`
  flex-direction: column;
  align-items: start;
`;

const Title = styled.p`
  width: 70px;
  font-size: 14px;
  margin-bottom: 5px;  
  text-align: left;
  padding-bottom: 0;
`;
const InputFileButton = styled.input`
  cursor: pointer;
  margin: 14px 0;
  color: transparent;
  width: calc(100% - 24px);

  ::-webkit-file-upload-button {
    cursor: pointer;
    color: #fff;
    background: #75759f;
    border-radius: 8px;
    border: none;
    padding: 15px 17px;
    text-transform: uppercase;
    width: 100%;
  }
`;

interface SelectButtonProps {
    clicked: boolean;
}

const SelectButton = styled.button<SelectButtonProps>`
  padding: 8px;
  margin: 2px;
  background: ${props => props.clicked ? "linear-gradient(135deg, #499ACC, #E60754)" : "#fff"};
  color: ${props => props.clicked ? "#fff" : "#000"};
  border: ${props => props.clicked ? "#fff 1px solid" : "#000 1px solid"};
  border-radius: 8px;
  cursor: pointer;
`;
const FinishButton = styled.button`
  padding: 15px;
  background: linear-gradient(135deg, #499ACC, #E60754);
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: calc(100% - 24px);
  margin-bottom: 10px;
`;

interface AddModalProps {
    showModal: any;
    apiKey: string;
    loadData: any;
}

const AddModal: React.FC<AddModalProps> = ({showModal, apiKey, loadData}) => {

    const [name, setName] = useState("");
    const [price, setPrice] = useState("0");
    const [categoryId, setCategoryId] = useState(0);
    const [materialsId, setMaterialsId] = useState(["0"]);
    const [colorsId, setColorsId] = useState(["0"]);
    const handleChangeName = useCallback((event: any) => setName(event.target.value), []);
    const handleChangePrice = useCallback((event: any) => setPrice(event.target.value), []);

    const [files, setFiles] = useState(null);
    const [filesBinary, setFilesBinary] = useState(null);
    const [fileNames, setFileNames] = useState(null);
    const [fileUrls, setFileUrls] = useState(null);
    const [isFilesGood, setIsFilesGood] = useState(true);
    const handleChangeFiles = useCallback(
        async (event: any) => {
            setIsFilesGood(true);
            const addedFiles = event.target.files;
            // @ts-ignore
            const names = [];
            // @ts-ignore
            const urls = [];
            // @ts-ignore
            const uploadableFiles = [];
            setFiles(addedFiles);
            const checkAllFiles = async () => {
                for (let i = 0; i < addedFiles.length; i++) {
                    names.push(addedFiles[i].name);
                    urls.push(URL.createObjectURL(addedFiles[i]));
                    uploadableFiles.push(addedFiles[i]);
                    if (addedFiles[i].type === "image/jpeg" || addedFiles[i].type === "image/jpg" || addedFiles[i].type === "image/png") {
                        console.log("good file");
                    } else setIsFilesGood(false);
                }
            };
            await checkAllFiles();
            // @ts-ignore
            setFilesBinary(uploadableFiles);
            // @ts-ignore
            setFileNames(names);
            // @ts-ignore
            setFileUrls(urls);
        },
        [],
    );

    const [sending, setSending] = useState(false);

    const sendFilesToBackend = async () => {
        setSending(true);
        const formData = new FormData();
        console.log(filesBinary);
        const addFiles = async () => {
            // @ts-ignore
            filesBinary.map((file) => formData.append(`Images`, file));
        };
        await addFiles();

        formData.append("Name", name);
        formData.append("Price", price);
        formData.append("CategoryId", categoryId.toString());
        materialsId.map(item => {
            formData.append("MaterialIds", item);
        });
        colorsId.map(item => {
            formData.append("ColorIds", item);
        });
        formData.append("AccessKey", apiKey);

        await axios.post(POST_ITEM_LINK, formData)
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });
        setSending(false);
        showModal(false)
        loadData()
    };

    const returnArrayValue = (arr: any, id: number) => {
        const stringId = id.toString();
        const newArr = [...arr];
        console.log(newArr);
        if (arr.includes(stringId)) {
            newArr.splice(newArr.findIndex((x: any) => x === stringId), 1);
        } else {
            newArr.push(stringId);
        }
        return newArr;
    };

    return (
        <ModalWrapper>
            <ModalWindow>
                <FlexRow style={{justifyContent: "end", marginLeft: 0}}>
                    <div onClick={() => showModal(false)} style={{cursor:"pointer"}}>
                        <img src="images/close.png" alt="" width={16}/>
                    </div>
                </FlexRow>
                <FlexColumn>
                    <Title>Имя</Title>
                    <StyledInput placeholder="" value={name} onChange={handleChangeName}/>
                </FlexColumn>
                <FlexColumn>
                    <Title>Цена</Title>
                    <StyledInput placeholder="" value={price} onChange={handleChangePrice}/>
                </FlexColumn>
                <FlexColumn>
                    <Title>Категория</Title>
                    <FlexRow style={{flexWrap: "wrap", marginLeft: 0}}>
                        {CATEGORY_LIST.map((item, id) => (
                            <SelectButton clicked={categoryId === id} onClick={() => setCategoryId(id)}>
                                {item.name}
                            </SelectButton>
                        ))}
                    </FlexRow>
                </FlexColumn>
                <FlexColumn>
                    <Title>Материалы</Title>
                    <FlexRow style={{flexWrap: "wrap", marginLeft: 0}}>
                        {MATERIALS.map((item, id) => (
                            <SelectButton clicked={materialsId.includes(id.toString())}
                                          onClick={() => setMaterialsId(returnArrayValue(materialsId, id))}>
                                {item}
                            </SelectButton>
                        ))}
                    </FlexRow>
                </FlexColumn>
                <FlexColumn>
                    <Title>Цвета</Title>
                    <FlexRow style={{flexWrap: "wrap", marginLeft: 0}}>
                        {COLORS.map((item, id) => (
                            <SelectButton clicked={colorsId.includes(id.toString())}
                                          onClick={() => setColorsId(returnArrayValue(colorsId, id))}>
                                {item.name}
                            </SelectButton>
                        ))}
                    </FlexRow>
                </FlexColumn>
                <FlexColumn>
                    <Title>Картинки:</Title>
                    <InputFileButton type="file" onChange={handleChangeFiles} multiple id="upload-photo"/>
                </FlexColumn>
                <FlexRow>
                    {fileNames && <Title style={{width: 200}}>Добавленые картинки: </Title>}
                </FlexRow>
                <FlexRow style={{flexWrap: "wrap", marginBottom: 20}}>
                    {    // @ts-ignore
                        fileUrls && fileUrls.map(imageLink => <img src={imageLink} alt=""  height={100} style={{margin: 5}}/> )}
                </FlexRow>
                <FinishButton onClick={sendFilesToBackend}>
                    ДОБАВИТЬ ТОВАР
                </FinishButton>
            </ModalWindow>
        </ModalWrapper>
    );
};


export default AddModal;